import axios from 'axios'
import router from '../router'

/**
 *
 * @type {{headers: {Accept: string, "Content-Type": string}, baseURL}}
 */
const config = {
  baseURL: process.env.VUE_APP_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
}
const httpClient = axios.create(config)

/**
 * @param config
 * @returns {*}
 */
const authInterceptor = config => {
  /** add auth token */
  const token = localStorage.getItem('jwt')
  config.headers.Authorization = `Bearer ${token}`
  return config
}

const loggerInterceptor = config => {
  return config
}

/** Adding the request interceptors */
httpClient.interceptors.request.use(authInterceptor)
httpClient.interceptors.request.use(loggerInterceptor)

/** Adding the response interceptors */
httpClient.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response.status === 401) {
      router.push('/login').then()
    }
    return Promise.reject(error)
  }
)

export default httpClient
