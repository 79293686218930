export default  {
    namespaced: true,
    state: {
        user: null,
        isAdmin: false,
        abilities:[]
    },
    mutations : {
        settingUserSession(state, user) {
            state.user = user;
            state.isAdmin = user.is_admin;
            state.abilities = user.my_abilities;
        }
    },
    actions: {
        setSession ({commit}) {
            let user = localStorage.getItem('user');
            user = JSON.parse(user);
            commit('settingUserSession', user);
        }
    },
    getters:{
        user (state) {
            return state.user;
        },
        isAdmin (state) {
            return state.isAdmin
        },
        abilities (state) {
            return state.abilities
        },
    }
}

