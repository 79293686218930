<template>
  <div id="app">
    <Toast :base-z-index="20000" ></Toast>
    <ConfirmPopup style="z-index: 20000"></ConfirmPopup>

    <router-view></router-view>
  </div>
</template>

<!--<style src="./style.scss" lang="scss"></style>-->

<script>
export default {
  name: 'app',
  metaInfo: {
    title: 'Index',
    titleTemplate: '%s - Admin'
  },
  updated () {
    // Remove loading state
    setTimeout(() => document.body.classList.remove('app-loading'), 155)
  }
}
</script>
<style>
.p-dropdown-item{
  text-align: left!important;
}
.p-datatable table {
  table-layout: fixed!important;
}
 body{
   zoom: 85%;
 }
.p-fileupload-choose {
  position: relative;
  overflow: hidden;
  display: inline !important;
}

.p-fileupload .p-fileupload-buttonbar .p-button {
  display: none;
}
</style>
