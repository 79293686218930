export default  {
    namespaced: true,
    state: {
        home: {icon: 'pi pi-home', to: '/admin'},
        items: [],
    },
    mutations : {
        settingItems(state, items) {
            state.items = items;
        }
    },
    actions: {
        setItems ({commit}, items) {
            commit('settingItems', items);
        }
    },
    getters:{
        breadcrumb (state) {
            return state
        }

    }
}

