export default {
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    onClick(event) {
      this.expanded = !this.expanded;
      event.preventDefault();
    },
    async logout() {
      localStorage.clear();
      const route = { name: "login" };
      await this.$router.push(route);
    },
  },
  computed: {
    user() {
      const userKey = "user";
      return JSON.parse(localStorage.getItem(userKey));
    },
  },
};
