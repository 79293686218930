export default  {
    namespaced: true,
    state: {
        country: null,
        holder: null,
        balance: null,
    },
    mutations : {
        settingCountry(state, country) {
            state.country = country;
        },
        settingHolder(state, holder) {
            state.holder = holder;
        },
        settingBalance(state, balance) {
            state.balance = balance;
        },
    },
    actions: {
        setCountry ({commit}, country) {
            commit('settingCountry', country);
        },
        setHolder ({commit}, holder) {
            commit('settingHolder', holder);
        },
        setBalance ({commit}, balance) {
            commit('settingBalance', balance);
        }
    },
    getters:{
        holder (state) {
            return state.holder;
        },
        country (state) {
            return state.country
        },
        balance (state) {
            return state.balance
        },

    }
}

